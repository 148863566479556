import { ClinicalAppointmentType } from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { TableCell } from '@mui/material';
import { GetClinicalAppointmentsForMember_getClinicalAppointmentsForMember_appointments } from 'app/patients/generated/GetClinicalAppointmentsForMember';
import { NoteTableCard } from 'app/sortable-table/note-card/NoteTableCard';
import { useAppState } from 'app/state';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import moment from 'moment';
import React from 'react';
import { shouldShowZoomButton } from 'utils';
import { getTimezone } from 'utils/dateTime';

import { AppointmentsAndNotesCursor } from '../AppointmentsAndNotesAPIContext';
import { NoteAction } from '../types';
import { getAppointmentTypeDetails } from '../utils';
import { AppointmentAppStatusCard } from './AppointmentAppStatusCard';
import { AppointmentNoteStatusCard } from './AppointmentNoteStatusCard';

export const AppointmentRow = (props: {
  appointment: GetClinicalAppointmentsForMember_getClinicalAppointmentsForMember_appointments;
  dataTestId?: string;
  onClick?: (note: AppointmentsAndNotesCursor) => Promise<void>;
}) => {
  const { appointment, dataTestId, onClick } = props;
  const {
    allowedNoteActions,
    transientFeatureFlags: { enable_psych_notes: psychNoteEnabled },
  } = useFeatureFlags();
  const { timezone } = useAppState((_) => _.user);
  const localTimezone = getTimezone(timezone);

  const start = moment.tz(appointment.start, localTimezone);
  const startDate = start.format('MMM D, YYYY'); // Oct 24, 2022
  const startTime = start.format('h:mm'); // 10:00
  const endTime = moment.tz(appointment.end, localTimezone).format('h:mma z'); // 10:00am PST

  return (
    <>
      <NoteTableCard
        dataTestId={dataTestId}
        note={appointment}
        startDate={startDate}
        dateString={`${startTime}-${endTime}`}
        details={getAppointmentTypeDetails(
          appointment.type || ClinicalAppointmentType.THERAPY_INTAKE,
        )}
        careTeamMember={appointment.clinician?.fullName}
        showZoomButton={shouldShowZoomButton(start, localTimezone)}
      />
      <TableCell>
        <AppointmentAppStatusCard appointment={appointment} />
      </TableCell>
      <TableCell>
        <AppointmentNoteStatusCard
          allowedNoteActions={allowedNoteActions}
          signedLockedAction={NoteAction.VIEW}
          psychNoteEnabled={psychNoteEnabled}
          appointment={appointment}
          onClick={onClick}
        />
      </TableCell>
    </>
  );
};
