import 'styles/App.scss';

import { UserRole } from '@headspace/carehub-graphql/dist/generated/globalTypes';
import * as Sentry from '@sentry/react';
import AppointmentScreenWithRouter from 'app/appointments/AppointmentScreen';
import AppointmentsScreen from 'app/appointments/AppointmentsScreen';
import { EventScreen } from 'app/appointments/EventContainer';
import NewAppointmentScreenWithRouter from 'app/appointments/NewAppointmentScreen';
import { NewEventScreen } from 'app/appointments/NewEventContainer';
import ScheduleAppointmentListScreenWithRouter from 'app/appointments/ScheduleAppointmentListScreen';
import ScheduleScreenWithRouter from 'app/appointments/ScheduleScreen';
import DashboardWithRouter from 'app/care-metrics/DashboardScreen';
import CareHubScreen from 'app/CareHubScreen';
import ClinicianSettingsScreen from 'app/clinician/ClinicianSettingsScreen';
import CoachScreen from 'app/coach/CoachScreen';
import MemberSearch from 'app/member-support/MemberSearch';
import MemberSearchScreen from 'app/member-support/MemberSearchScreen';
import {
  ClinicalNotesScreenRoutes,
  OtherClinicalNotesScreenRoutes,
} from 'app/notes-ui/ClinicalNotesScreenRoutes';
import { OktaCallbackScreen } from 'app/OktaCallbackScreen';
import CoachDependentIntakeScreen from 'app/patients/CoachDependentIntakeScreen';
import DependentIntakeScreen from 'app/patients/DependentIntakeScreen';
import ERxNotificationsScreen from 'app/patients/ERxNotificationsScreen';
import PatientsScreenV2 from 'app/patients/PatientsScreenV2';
import { useAppState, useDispatch } from 'app/state';
import { pageLoad } from 'app/state/amplitude/actions/etc';
import { useLogger } from 'app/state/log/useLogger';
import { Routes, TopLevelRoutes } from 'app/top-nav/Routes';
import { VaultLoginScreen } from 'app/vault/components/login/VaultLoginScreen';
import React, { ReactElement, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { NotLoggedInScreen } from 'shared-components/error-state/NotLoggedInScreen';
import Screen from 'shared-components/Screen';
import {
  CLINICIAN_OR_SUPERVISOR_USER_ROLE,
  COACH_OR_SUPERVISOR_USER_ROLE,
  isClinicianOrSupervisor,
  SUPERVISOR_USER_ROLE,
} from 'utils';

import { SecureRoute } from './SecureRoute';

/** Our top level routes.
 *
 * The <Redirect from="/" to="/" /> at the bottom is a catch-all
 * that will redirect any non-matched path to the home screen. It reads funny because
 *  the from="/" parameter matches any url that starts with "/", but the to="/" is an exact
 *   url
 */

function AppRoutes() {
  const role = useAppState(({ user }) => user.role);
  const SentryRoute = Sentry.withSentryRouting(Route);
  const dispatch = useDispatch();
  const logger = useLogger();

  useEffect(() => {
    if (!role) return;

    dispatch(pageLoad({ role }));
    logger.info('page_load');
  }, [role]);

  return (
    <Switch>
      <SecureRoute
        exact={true}
        path="/"
        permittedRoles={[
          ...CLINICIAN_OR_SUPERVISOR_USER_ROLE,
          UserRole.MEMBER_SUPPORT,
          UserRole.COACH,
          UserRole.COACH_SUPERVISOR,
        ]}
        component={HomeScreen}
      />
      <SecureRoute
        // clinical notes attached to a specific member e.g. termination note
        path={Routes.NOTES}
        isVaultRequired={true}
        permittedRoles={CLINICIAN_OR_SUPERVISOR_USER_ROLE}
        component={withScreen(<OtherClinicalNotesScreenRoutes />, {
          'data-testid': 'non-clinical-notes-screen',
          inboxEnabled: true,
        })}
      />

      <SecureRoute
        path={Routes.PATIENTS_TEEN_INTAKE}
        permittedRoles={CLINICIAN_OR_SUPERVISOR_USER_ROLE}
        component={DependentIntakeScreen}
      />

      <SecureRoute
        path={Routes.COACHING_PATIENTS_TEEN_INTAKE}
        permittedRoles={COACH_OR_SUPERVISOR_USER_ROLE}
        component={CoachDependentIntakeScreen}
      />

      <SecureRoute
        path={Routes.PATIENTS}
        isVaultRequired={true}
        permittedRoles={CLINICIAN_OR_SUPERVISOR_USER_ROLE}
        component={withScreen(<PatientsScreenV2 />, {
          'data-testid': 'patients-screen',
          inboxEnabled: true,
        })}
      />

      <SecureRoute
        exact={true}
        path={Routes.APPOINTMENTS}
        permittedRoles={CLINICIAN_OR_SUPERVISOR_USER_ROLE}
        component={withScreen(<AppointmentsScreen />, {
          'data-testid': 'appointments-screen',
          inboxEnabled: true,
        })}
      />
      <SecureRoute
        exact={true}
        path={Routes.APPOINTMENT_CREATE}
        permittedRoles={[
          ...CLINICIAN_OR_SUPERVISOR_USER_ROLE,
          UserRole.MEMBER_SUPPORT,
        ]}
        component={withScreen(<NewAppointmentScreenWithRouter />, {
          'data-testid': 'new-appointment-screen',
          inboxEnabled: isClinicianOrSupervisor(role),
        })}
      />
      <SecureRoute
        exact={true}
        path={Routes.APPOINTMENT}
        permittedRoles={[
          ...CLINICIAN_OR_SUPERVISOR_USER_ROLE,
          UserRole.MEMBER_SUPPORT,
        ]}
        component={withScreen(<AppointmentScreenWithRouter />, {
          'data-testid': 'appointment-screen',
          inboxEnabled: isClinicianOrSupervisor(role),
        })}
      />
      <SecureRoute
        exact={true}
        path={Routes.EVENT_CREATE}
        permittedRoles={CLINICIAN_OR_SUPERVISOR_USER_ROLE}
        component={withScreen(<NewEventScreen />, {
          'data-testid': 'new-event-screen',
          inboxEnabled: true,
        })}
      />
      <SecureRoute
        exact={true}
        path={Routes.EVENT}
        permittedRoles={CLINICIAN_OR_SUPERVISOR_USER_ROLE}
        component={withScreen(<EventScreen />, {
          'data-testid': 'event-screen',
          inboxEnabled: true,
        })}
      />
      <SecureRoute
        exact={true}
        path={Routes.SCHEDULE}
        permittedRoles={CLINICIAN_OR_SUPERVISOR_USER_ROLE}
        component={withScreen(<ScheduleScreenWithRouter />, {
          'data-testid': 'schedule-screen',
          inboxEnabled: true,
        })}
      />
      <SecureRoute
        exact={true}
        path={Routes.SCHEDULE_APPOINTMENT_LIST}
        permittedRoles={CLINICIAN_OR_SUPERVISOR_USER_ROLE}
        component={withScreen(<ScheduleAppointmentListScreenWithRouter />, {
          'data-testid': 'schedule-appointment-list-screen',
          inboxEnabled: true,
        })}
      />
      <SecureRoute
        // clinical notes attached to a specific appointment
        path={Routes.APPOINTMENT_NOTES}
        isVaultRequired={true}
        permittedRoles={CLINICIAN_OR_SUPERVISOR_USER_ROLE}
        component={withScreen(<ClinicalNotesScreenRoutes />, {
          'data-testid': 'clinical-notes-screen',
          inboxEnabled: true,
        })}
      />
      <SecureRoute
        path={Routes.MEMBERS}
        isVaultRequired={true}
        permittedRoles={[UserRole.MEMBER_SUPPORT, ...SUPERVISOR_USER_ROLE]}
        component={withScreen(<MemberSearch />, {
          'data-testid': 'member-screen',
          inboxEnabled: false,
        })}
      />
      <SecureRoute
        exact={true}
        path={TopLevelRoutes.MEMBERS}
        permittedRoles={[UserRole.MEMBER_SUPPORT, ...SUPERVISOR_USER_ROLE]}
        component={withScreen(<MemberSearchScreen />, {
          'data-testid': 'member-search-screen',
          inboxEnabled: false,
        })}
      />
      <SecureRoute
        exact={true}
        path={TopLevelRoutes.ERX_NOTIFICATIONS}
        permittedRoles={CLINICIAN_OR_SUPERVISOR_USER_ROLE}
        isVaultRequired={true}
        component={withScreen(<ERxNotificationsScreen />, {
          'data-testid': 'prescriptions',
          inboxEnabled: true,
        })}
      />
      <SecureRoute
        exact={true}
        permittedRoles={CLINICIAN_OR_SUPERVISOR_USER_ROLE}
        path={TopLevelRoutes.SETTINGS}
        component={withScreen(<ClinicianSettingsScreen />, {
          'data-testid': 'clinician-settings-screen',
          inboxEnabled: true,
        })}
      />
      <SecureRoute
        isVaultRequired={true}
        permittedRoles={[UserRole.COACH, UserRole.COACH_SUPERVISOR]}
        path={Routes.COACHING_MEMBERS}
        component={withScreen(<CoachScreen />, {
          'data-testid': 'coach-screen',
          inboxEnabled: true,
        })}
      />
      <SecureRoute
        exact={true}
        path={Routes.CARE_METRICS}
        permittedRoles={CLINICIAN_OR_SUPERVISOR_USER_ROLE}
        component={withScreen(<DashboardWithRouter />, {
          'data-testid': 'care-metrics',
          inboxEnabled: true,
        })}
      />

      <SentryRoute
        exact={true}
        path={TopLevelRoutes.VAULT_LOGIN}
        component={VaultLoginScreen}
      />
      <SentryRoute
        exact={true}
        path={Routes.LOGIN}
        component={OktaCallbackScreen}
      />
      <SentryRoute
        exact={true}
        path={Routes.LOGOUT}
        component={NotLoggedInScreen}
      />
      <Redirect from="/" to="/" />
    </Switch>
  );
}

function withScreen(
  component: ReactElement,
  {
    'data-testid': testId,
    inboxEnabled,
  }: { 'data-testid': string; inboxEnabled: boolean },
): () => ReactElement {
  const Component = inboxEnabled ? CareHubScreen : Screen;

  if (process.env.NODE_ENV === 'test') {
    return () => (
      <div data-testid={testId}>
        <Component>{component}</Component>
      </div>
    );
  }
  return () => <Component>{component}</Component>;
}

export const homePathForRole: Record<UserRole, string> = {
  [UserRole.CLINICIAN]: TopLevelRoutes.PATIENTS,
  [UserRole.THERAPIST]: TopLevelRoutes.PATIENTS,
  [UserRole.PSYCHIATRIST]: TopLevelRoutes.PATIENTS,
  [UserRole.CLINICAL_SUPERVISOR]: TopLevelRoutes.PATIENTS,
  [UserRole.THERAPIST_SUPERVISOR]: TopLevelRoutes.PATIENTS,
  [UserRole.PSYCHIATRIST_SUPERVISOR]: TopLevelRoutes.PATIENTS,
  [UserRole.MEMBER_SUPPORT]: TopLevelRoutes.MEMBERS,
  [UserRole.COACH_SUPERVISOR]: TopLevelRoutes.COACHING,
  [UserRole.COACH]: TopLevelRoutes.COACHING,
};

export function HomeScreen() {
  const role = useAppState((_) => _.user.role);

  if (!role) {
    return <div>Not Authorized :(</div>;
  }

  const destination = homePathForRole[role];
  return <Redirect to={destination} />;
}

export default AppRoutes;
