/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AdaptabilitySeverity {
  HIGH = "HIGH",
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  VERY_HIGH = "VERY_HIGH",
}

export enum AddMemberSupportErrorCode {
  UNEXPECTED_ERROR = "UNEXPECTED_ERROR",
}

export enum AppointmentCadence {
  EVERY_2_WEEKS = "EVERY_2_WEEKS",
  EVERY_3_WEEKS = "EVERY_3_WEEKS",
  EVERY_4_WEEKS = "EVERY_4_WEEKS",
  WEEKLY = "WEEKLY",
}

export enum AppointmentStatus {
  CANCELLED = "CANCELLED",
  CANCELLED_CLINICIAN = "CANCELLED_CLINICIAN",
  COMPLETE = "COMPLETE",
  CONFIRMED = "CONFIRMED",
  DELETED = "DELETED",
  LATE_CANCELLED = "LATE_CANCELLED",
  NO_SHOW = "NO_SHOW",
  PROVIDER_NO_SHOW = "PROVIDER_NO_SHOW",
  RESCHEDULED = "RESCHEDULED",
  TENTATIVE = "TENTATIVE",
  UNKNOWN = "UNKNOWN",
}

export enum AppointmentType {
  PSYCHIATRY = "PSYCHIATRY",
  PSYCHIATRY_INTAKE = "PSYCHIATRY_INTAKE",
  PSYCHIATRY_PROGRESS = "PSYCHIATRY_PROGRESS",
  THERAPY = "THERAPY",
  THERAPY_INTAKE = "THERAPY_INTAKE",
  THERAPY_PROGRESS = "THERAPY_PROGRESS",
}

export enum AvailabilityTypeEnum {
  CHAT = "CHAT",
  VIDEO = "VIDEO",
}

export enum CSSRSScore {
  HIGH = "HIGH",
  LOW = "LOW",
  MODERATE = "MODERATE",
  NO_RISK = "NO_RISK",
}

export enum CallToAction {
  CREATE = "CREATE",
  Create = "Create",
  REVIEW = "REVIEW",
  REVIEW_PRESCRIPTIONS = "REVIEW_PRESCRIPTIONS",
  Review = "Review",
  ReviewPrescriptions = "ReviewPrescriptions",
  UPDATE = "UPDATE",
  Update = "Update",
}

export enum CareServiceProvisionType {
  COACHING = "COACHING",
  VIDEO_COACHING = "VIDEO_COACHING",
}

export enum CareTeamRole {
  COACH = "COACH",
  MEMBER_SUPPORT = "MEMBER_SUPPORT",
  PSYCHIATRIST = "PSYCHIATRIST",
  THERAPIST = "THERAPIST",
}

export enum ChartAreaViewed {
  intake = "intake",
  survey_scores = "survey_scores",
}

export enum ChatConversationState {
  DONE = "DONE",
  OPEN = "OPEN",
}

export enum ClinicalAppointmentNoteLocation {
  DR_CHRONO = "DR_CHRONO",
  VAULT = "VAULT",
}

export enum ClinicalAppointmentNoteStatus {
  DRAFT = "DRAFT",
  SIGNED_AND_LOCKED = "SIGNED_AND_LOCKED",
}

export enum ClinicalAppointmentNoteType {
  PSYCHIATRY_INTAKE = "PSYCHIATRY_INTAKE",
  PSYCHIATRY_PROGRESS = "PSYCHIATRY_PROGRESS",
  THERAPY_INTAKE = "THERAPY_INTAKE",
  THERAPY_PROGRESS = "THERAPY_PROGRESS",
  UNSPECIFIED = "UNSPECIFIED",
}

export enum ClinicalAppointmentRecurrenceInterval {
  FOUR_WEEKS = "FOUR_WEEKS",
  ONE_WEEK = "ONE_WEEK",
  TWO_WEEKS = "TWO_WEEKS",
}

export enum ClinicalAppointmentStatus {
  Cancelled = "Cancelled",
  CancelledClinician = "CancelledClinician",
  Complete = "Complete",
  Confirmed = "Confirmed",
  Deleted = "Deleted",
  LateCancelled = "LateCancelled",
  NoShow = "NoShow",
  NotConfirmed = "NotConfirmed",
  ProviderNoShow = "ProviderNoShow",
  Rescheduled = "Rescheduled",
  Tentative = "Tentative",
  Unknown = "Unknown",
}

export enum ClinicalAppointmentType {
  PSYCHIATRY_INTAKE = "PSYCHIATRY_INTAKE",
  PSYCHIATRY_PROGRESS = "PSYCHIATRY_PROGRESS",
  THERAPY_INTAKE = "THERAPY_INTAKE",
  THERAPY_PROGRESS = "THERAPY_PROGRESS",
}

export enum ClinicalCoverageExtent {
  CLINICAL_COVERED = "CLINICAL_COVERED",
  CLINICAL_CUSTOM_NETWORK = "CLINICAL_CUSTOM_NETWORK",
  CLINICAL_IN_NETWORK = "CLINICAL_IN_NETWORK",
  CLINICAL_MIXED_NETWORK = "CLINICAL_MIXED_NETWORK",
  CLINICAL_OUT_OF_POCKET = "CLINICAL_OUT_OF_POCKET",
  CLINICAL_UNAVAILABLE = "CLINICAL_UNAVAILABLE",
}

export enum ClinicalEventType {
  INTERVIEW_PANEL = "INTERVIEW_PANEL",
  QA_WORK = "QA_WORK",
  SPECIAL_PROJECT = "SPECIAL_PROJECT",
  TRAINING = "TRAINING",
}

export enum ClinicalNoteStatus {
  DRAFT = "DRAFT",
  NOT_STARTED = "NOT_STARTED",
  SIGNED_AND_LOCKED = "SIGNED_AND_LOCKED",
}

export enum ClinicianAppointmentStatus {
  Cancelled = "Cancelled",
  CancelledClinician = "CancelledClinician",
  Complete = "Complete",
  Confirmed = "Confirmed",
  Deleted = "Deleted",
  LateCancelled = "LateCancelled",
  NoShow = "NoShow",
  NotConfirmed = "NotConfirmed",
  ProviderNoShow = "ProviderNoShow",
  Rescheduled = "Rescheduled",
  Tentative = "Tentative",
  Unknown = "Unknown",
}

export enum CoachCareTeamRoleEnum {
  BACKUP = "BACKUP",
  CLINICAL_REVIEWER = "CLINICAL_REVIEWER",
  CLINICAL_SUPERVISOR = "CLINICAL_SUPERVISOR",
  COACH = "COACH",
  COACH_SUPERVISOR = "COACH_SUPERVISOR",
  DROPIN = "DROPIN",
  SI_ASSESSMENT = "SI_ASSESSMENT",
  SUPPORT = "SUPPORT",
  TEAM_LEAD = "TEAM_LEAD",
}

export enum CoachShiftStatusEnum {
  ADMIN = "ADMIN",
  ALL_HANDS = "ALL_HANDS",
  COACH_MEETING = "COACH_MEETING",
  LUNCH = "LUNCH",
  MEETING = "MEETING",
  NOTES = "NOTES",
  OFFLINE = "OFFLINE",
  ONE_ON_ONE = "ONE_ON_ONE",
  ONLINE = "ONLINE",
  OTHER_MEETING = "OTHER_MEETING",
  PROCESS_GROUP = "PROCESS_GROUP",
  REPLY_ONLY = "REPLY_ONLY",
  REST_BREAK = "REST_BREAK",
  SHIFT_LEAD_MEETING = "SHIFT_LEAD_MEETING",
  SNAPS = "SNAPS",
  WRAP_UP = "WRAP_UP",
}

export enum CoachShiftType {
  CLINICAL_REVIEWER = "CLINICAL_REVIEWER",
  CLINICAL_SUPERVISOR = "CLINICAL_SUPERVISOR",
  COACH = "COACH",
  COACH_A = "COACH_A",
  COACH_A1 = "COACH_A1",
  COACH_A2 = "COACH_A2",
  COACH_B = "COACH_B",
  COACH_B1 = "COACH_B1",
  COACH_B2 = "COACH_B2",
  COACH_B3 = "COACH_B3",
  COACH_C = "COACH_C",
  COACH_C1 = "COACH_C1",
  COACH_C2 = "COACH_C2",
  COACH_C3 = "COACH_C3",
  COACH_D = "COACH_D",
  COACH_D1 = "COACH_D1",
  COACH_D2 = "COACH_D2",
  COACH_E = "COACH_E",
  COACH_E1 = "COACH_E1",
  COACH_E2 = "COACH_E2",
  COACH_E3 = "COACH_E3",
  COACH_E4 = "COACH_E4",
  COACH_F = "COACH_F",
  COACH_F1 = "COACH_F1",
  COACH_F10 = "COACH_F10",
  COACH_F11 = "COACH_F11",
  COACH_F12 = "COACH_F12",
  COACH_F2 = "COACH_F2",
  COACH_F3 = "COACH_F3",
  COACH_F4 = "COACH_F4",
  COACH_F5 = "COACH_F5",
  COACH_F6 = "COACH_F6",
  COACH_F7 = "COACH_F7",
  COACH_F8 = "COACH_F8",
  COACH_F9 = "COACH_F9",
  COACH_G = "COACH_G",
  COACH_SUPERVISOR = "COACH_SUPERVISOR",
  CONCIERGE = "CONCIERGE",
  LISTENER = "LISTENER",
}

export enum CoachType {
  BACKUP = "BACKUP",
  COACH = "COACH",
  DROPIN = "DROPIN",
  LEAD = "LEAD",
  SI = "SI",
  SUPPORT = "SUPPORT",
}

export enum CoachingSessionEventType {
  EVENT_TYPE_COACHING_SESSION = "EVENT_TYPE_COACHING_SESSION",
  EVENT_TYPE_INITIAL_CONSULT = "EVENT_TYPE_INITIAL_CONSULT",
  EVENT_TYPE_REMINDER = "EVENT_TYPE_REMINDER",
}

export enum CoachingSessionModificationScope {
  THIS_AND_FOLLOWING = "THIS_AND_FOLLOWING",
  THIS_ONLY = "THIS_ONLY",
}

export enum CoverageType {
  CLINICAL_PRE_PURCHASE = "CLINICAL_PRE_PURCHASE",
  EAP = "EAP",
  SINGLE_FEE = "SINGLE_FEE",
}

export enum CreditCardStatus {
  ADDED = "ADDED",
  EXPIRED = "EXPIRED",
  GUARDIAN_ADDED = "GUARDIAN_ADDED",
  GUARDIAN_EXPIRED = "GUARDIAN_EXPIRED",
  MISSING = "MISSING",
}

export enum CustomerType {
  consumer = "consumer",
  employer = "employer",
  legacy = "legacy",
}

export enum EscalateMemberToClinicalErrorCode {
  UNEXPECTED_ERROR = "UNEXPECTED_ERROR",
}

export enum Ethnicity {
  AFRICAN_AMERICAN = "AFRICAN_AMERICAN",
  ASIAN_AMERICAN = "ASIAN_AMERICAN",
  ASIAN_PACIFIC_ISLANDER = "ASIAN_PACIFIC_ISLANDER",
  CAUCASIAN = "CAUCASIAN",
  HISPANIC = "HISPANIC",
  MIDDLE_EASTERN_AMERICAN = "MIDDLE_EASTERN_AMERICAN",
  MIDDLE_EASTERN_NORTH_AFRICAN = "MIDDLE_EASTERN_NORTH_AFRICAN",
  NATIVE_AMERICAN = "NATIVE_AMERICAN",
  PREFER_NOT_TO_SAY = "PREFER_NOT_TO_SAY",
  SOMETHING_ELSE = "SOMETHING_ELSE",
  UNDISCLOSED = "UNDISCLOSED",
}

export enum GAD7Severity {
  MILD = "MILD",
  MINIMAL = "MINIMAL",
  MODERATE = "MODERATE",
  SEVERE = "SEVERE",
}

export enum Gender {
  FEMALE = "FEMALE",
  MALE = "MALE",
  OTHER = "OTHER",
}

export enum GenderIdentification {
  FEMALE = "FEMALE",
  MALE = "MALE",
  NON_BINARY = "NON_BINARY",
  PREFER_NOT_TO_SAY = "PREFER_NOT_TO_SAY",
  QUEER = "QUEER",
  SOMETHING_ELSE = "SOMETHING_ELSE",
  TRANSGENDER = "TRANSGENDER",
  TRANSGENDER_FEMALE = "TRANSGENDER_FEMALE",
  TRANSGENDER_MALE = "TRANSGENDER_MALE",
  UNDISCLOSED = "UNDISCLOSED",
}

export enum GoogleCalendarEventAttendanceStatus {
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
  NEEDS_ACTION = "NEEDS_ACTION",
  TENTATIVE = "TENTATIVE",
}

export enum GuardianRelationshipType {
  DEPENDENT = "DEPENDENT",
  GUARDIAN = "GUARDIAN",
  NONE = "NONE",
}

export enum InsightFrequency {
  FREQUENTLY = "FREQUENTLY",
  NEARLY_EVERY_DAY = "NEARLY_EVERY_DAY",
  NOT_AT_ALL = "NOT_AT_ALL",
  SEVERAL_DAYS = "SEVERAL_DAYS",
}

export enum IntiateVaultAuthChallengeResponseType {
  AUTH_CHALLENGE = "AUTH_CHALLENGE",
  REGISTRATION_REQUIRED = "REGISTRATION_REQUIRED",
}

export enum NoteLocation {
  DR_CHRONO = "DR_CHRONO",
  VAULT = "VAULT",
}

export enum NoteType {
  PSYCHIATRY_INTAKE = "PSYCHIATRY_INTAKE",
  PSYCHIATRY_PROGRESS = "PSYCHIATRY_PROGRESS",
  THERAPY_INTAKE = "THERAPY_INTAKE",
  THERAPY_PROGRESS = "THERAPY_PROGRESS",
  UNSPECIFIED = "UNSPECIFIED",
}

export enum PHQ9Severity {
  MILD = "MILD",
  MINIMAL = "MINIMAL",
  MODERATE = "MODERATE",
  MODERATELY_SEVERE = "MODERATELY_SEVERE",
  SEVERE = "SEVERE",
}

export enum PHQ9SuicidalIdeation {
  DENIED = "DENIED",
  ENDORSED = "ENDORSED",
  UNKNOWN = "UNKNOWN",
}

export enum PSSScore {
  HIGH = "HIGH",
  LOW = "LOW",
  MODERATE = "MODERATE",
}

export enum Pronouns {
  HE = "HE",
  PREFER_NOT_TO_SAY = "PREFER_NOT_TO_SAY",
  SHE = "SHE",
  SOMETHING_ELSE = "SOMETHING_ELSE",
  THEY = "THEY",
  UNDISCLOSED = "UNDISCLOSED",
  ZE = "ZE",
}

export enum Reason {
  AssessmentRiskAlert = "AssessmentRiskAlert",
  AtRiskPhq9Q9 = "AtRiskPhq9Q9",
  CareProviderNotesDue = "CareProviderNotesDue",
  ClinicalNotePastDue = "ClinicalNotePastDue",
  CoachingTeamLeadChanged = "CoachingTeamLeadChanged",
  DeactivationReminder = "DeactivationReminder",
  FollowUpReminder = "FollowUpReminder",
  GoalRevisionReminder = "GoalRevisionReminder",
  HighSurveyScore = "HighSurveyScore",
  IntakeRiskAlert = "IntakeRiskAlert",
  MissedSession = "MissedSession",
  NewActionableCollabMessage = "NewActionableCollabMessage",
  NewClinicalDocumentUpload = "NewClinicalDocumentUpload",
  NewCoachNoteUpdate = "NewCoachNoteUpdate",
  NewCollaborationChatMessage = "NewCollaborationChatMessage",
  NewERxAlert = "NewERxAlert",
  NewEntityCreated = "NewEntityCreated",
  PHQGADReminder = "PHQGADReminder",
  ReengagementReminder = "ReengagementReminder",
  RiskyChatDetected = "RiskyChatDetected",
  SICoachAdded = "SICoachAdded",
  SIReviewTriageNotes = "SIReviewTriageNotes",
  ScheduledSession = "ScheduledSession",
  StaleAppointmentStatus = "StaleAppointmentStatus",
  SurveyScoreImproved = "SurveyScoreImproved",
  SurveyScoreWorsened = "SurveyScoreWorsened",
  TerminationReminder = "TerminationReminder",
  TreatmentPlanReviewReminder = "TreatmentPlanReviewReminder",
}

export enum ReasonForCreation {
  ASSESSMENT_RISK_ALERT = "ASSESSMENT_RISK_ALERT",
  AT_RISK_PHQ9_Q9 = "AT_RISK_PHQ9_Q9",
  CARE_PROVIDER_NOTES_DUE = "CARE_PROVIDER_NOTES_DUE",
  CLINICAL_NOTES_FOR_APPOINTMENT_PAST_DUE = "CLINICAL_NOTES_FOR_APPOINTMENT_PAST_DUE",
  COACHING_TEAM_LEAD_CHANGED = "COACHING_TEAM_LEAD_CHANGED",
  DEACTIVATION_REMINDER = "DEACTIVATION_REMINDER",
  FOLLOW_UP_REMINDER = "FOLLOW_UP_REMINDER",
  GOAL_REVISION_REMINDER = "GOAL_REVISION_REMINDER",
  HIGH_SURVEY_SCORE = "HIGH_SURVEY_SCORE",
  INTAKE_RISK_ALERT = "INTAKE_RISK_ALERT",
  MISSED_SESSION = "MISSED_SESSION",
  NEW_ACTIONABLE_COLLAB_MESSAGE = "NEW_ACTIONABLE_COLLAB_MESSAGE",
  NEW_CLINICAL_DOCUMENT_UPLOAD = "NEW_CLINICAL_DOCUMENT_UPLOAD",
  NEW_COACH_NOTE_UPDATE = "NEW_COACH_NOTE_UPDATE",
  NEW_COLLABORATION_CHAT_MESSAGE = "NEW_COLLABORATION_CHAT_MESSAGE",
  NEW_ENTITY_CREATED = "NEW_ENTITY_CREATED",
  NEW_ERX_ALERT = "NEW_ERX_ALERT",
  PHQGAD_REMINDER = "PHQGAD_REMINDER",
  REENGAGEMENT_REMINDER = "REENGAGEMENT_REMINDER",
  RISKY_CHAT_DETECTED = "RISKY_CHAT_DETECTED",
  SCHEDULED_SESSION = "SCHEDULED_SESSION",
  SI_COACH_ADDED = "SI_COACH_ADDED",
  SI_REVIEW_TRIAGE_NOTES = "SI_REVIEW_TRIAGE_NOTES",
  STALE_APPOINTMENT_STATUS = "STALE_APPOINTMENT_STATUS",
  SURVEY_SCORE_IMPROVED = "SURVEY_SCORE_IMPROVED",
  SURVEY_SCORE_WORSENED = "SURVEY_SCORE_WORSENED",
  TERMINATION_REMINDER = "TERMINATION_REMINDER",
  TREATMENT_PLAN_REVIEW_REMINDER = "TREATMENT_PLAN_REVIEW_REMINDER",
}

export enum ReferralSource {
  ACCOLADE = "ACCOLADE",
  KAISER = "KAISER",
  PENN_STATE_UNIVERSITY = "PENN_STATE_UNIVERSITY",
  UNKNOWN = "UNKNOWN",
}

export enum ScheduledSessionType {
  DROP_IN = "DROP_IN",
  FOLLOW_UP = "FOLLOW_UP",
  INITIAL_CONSULT = "INITIAL_CONSULT",
}

export enum ServiceCoveredType {
  BOTH = "BOTH",
  PSYCHIATRY = "PSYCHIATRY",
  THERAPY = "THERAPY",
}

export enum ServiceType {
  BOTH = "BOTH",
  COACHING = "COACHING",
  PSYCHIATRY = "PSYCHIATRY",
  THERAPY = "THERAPY",
  UNKNOWN = "UNKNOWN",
}

export enum SortDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export enum Status {
  Active = "Active",
  Completed = "Completed",
  Dismissed = "Dismissed",
}

export enum StrengthFrequency {
  NOT_TRUE_AT_ALL = "NOT_TRUE_AT_ALL",
  OFTEN_TRUE = "OFTEN_TRUE",
  RARELY_TRUE = "RARELY_TRUE",
  SOMETIMES_TRUE = "SOMETIMES_TRUE",
  TRUE_NEARLY_ALL_THE_TIME = "TRUE_NEARLY_ALL_THE_TIME",
}

export enum TaskStatus {
  ACTIVE = "ACTIVE",
  COMPLETED = "COMPLETED",
  DISMISSED = "DISMISSED",
}

export enum TherapistRole {
  PSYCH = "PSYCH",
  THERA = "THERA",
}

export enum TransferMemberToSICoachErrorCode {
  ALL_SI_COACHES_AT_PEAK = "ALL_SI_COACHES_AT_PEAK",
  ALL_SI_COACHES_OFFLINE = "ALL_SI_COACHES_OFFLINE",
}

export enum UserRole {
  CLINICAL_SUPERVISOR = "CLINICAL_SUPERVISOR",
  CLINICIAN = "CLINICIAN",
  COACH = "COACH",
  COACH_SUPERVISOR = "COACH_SUPERVISOR",
  MEMBER_SUPPORT = "MEMBER_SUPPORT",
  PSYCHIATRIST = "PSYCHIATRIST",
  PSYCHIATRIST_SUPERVISOR = "PSYCHIATRIST_SUPERVISOR",
  THERAPIST = "THERAPIST",
  THERAPIST_SUPERVISOR = "THERAPIST_SUPERVISOR",
}

export enum VaultAuditLogEventType {
  GROUP_CREATED = "GROUP_CREATED",
  GROUP_UPDATED = "GROUP_UPDATED",
  ITEM_CREATED = "ITEM_CREATED",
  ITEM_DELETED = "ITEM_DELETED",
  ITEM_SHARED = "ITEM_SHARED",
  ITEM_UPDATED = "ITEM_UPDATED",
  ITEM_VIEWED = "ITEM_VIEWED",
}

export enum VaultEncoding {
  BASE_64_BUFFER = "BASE_64_BUFFER",
}

export enum VaultEncryptionVersion {
  V0 = "V0",
}

export enum VaultItemPermissions {
  READ_ONLY = "READ_ONLY",
  WRITABLE = "WRITABLE",
  WRITABLE_BY_ALL = "WRITABLE_BY_ALL",
}

export enum VaultItemSortField {
  CREATED_AT = "CREATED_AT",
}

export enum VaultItemSortOrder {
  ASC = "ASC",
  DESC = "DESC",
}

export enum VaultKeyType {
  MASTER_KEY = "MASTER_KEY",
  PUBLIC_KEY = "PUBLIC_KEY",
}

export enum VaultPublicKeyVersion {
  V0 = "V0",
}

export enum VaultSystemName {
  CLINICAL_DOCUMENT_SYNC_PROCESS = "CLINICAL_DOCUMENT_SYNC_PROCESS",
  CLINICAL_NOTES_SYNC_PROCESS = "CLINICAL_NOTES_SYNC_PROCESS",
  COLLABORATION_SYNC_PROCESS = "COLLABORATION_SYNC_PROCESS",
}

export interface AddMemberSupportInput {
  memberId: string;
  reason: string;
}

export interface AssignHomeworkToMemberInput {
  memberId: number;
  contentType: string;
  contentId: string;
}

export interface BulkUpdateCareProviderTaskInput {
  tasks: UpdateCareProviderTaskFieldsInput[];
}

export interface CancelMemberCoachingSessionInput {
  coachingSessionId: string;
  modificationScope?: CoachingSessionModificationScope | null;
}

export interface ClearCareProviderTasksByReasonInput {
  memberId: string;
  reasons: (Reason | null)[];
}

export interface ClearClinicalTasksMutationInput {
  ids?: (string | null)[] | null;
  reason?: string | null;
}

export interface ClinicalAppointmentFiltersInput {
  windowStart?: any | null;
  windowEnd?: any | null;
  status?: ClinicianAppointmentStatus | null;
  appointmentStatus?: ClinicalAppointmentStatus | null;
  appointmentStatus_In?: (ClinicalAppointmentStatus | null)[] | null;
  appointmentType_In?: (ClinicalAppointmentType | null)[] | null;
}

export interface ClinicalAppointmentRecurrenceInput {
  interval: ClinicalAppointmentRecurrenceInterval;
  occurrenceCount: any;
}

export interface ClinicalEventFiltersInput {
  windowStart?: any | null;
  windowEnd?: any | null;
}

export interface ClinicalEventRecurrenceInput {
  interval: ClinicalAppointmentRecurrenceInterval;
  occurrenceCount: any;
}

export interface ClinicianNewAppointmentsConfigInput {
  clinicianId: string;
}

export interface CliniciansFiltersInput {
  memberId?: string | null;
  fullName?: string | null;
}

export interface CloseChatConversationWithMemberInput {
  gingerId: string;
  lastKnownTimetoken?: string | null;
}

export interface CompleteRiskAssessmentInput {
  coachType: CoachType;
  memberId: string;
  leadCoachGingerId?: string | null;
}

export interface CompleteVaultAuthChallengeInput {
  signedChallenge: string;
  userId: string;
}

export interface CreateCSCOutreachPauseRequestInput {
  userId: string;
  pause: boolean;
  note?: string | null;
  reason?: string | null;
  unpauseAt?: any | null;
  updatedBy?: string | null;
}

export interface CreateClinicalAppointmentForClinicianInput {
  clinicianId: number;
  appointmentData: CreateClinicalAppointmentInput;
}

export interface CreateClinicalAppointmentForMeInput {
  appointmentData: CreateClinicalAppointmentInput;
}

export interface CreateClinicalAppointmentInput {
  memberId: number;
  type: ClinicalAppointmentType;
  status?: ClinicianAppointmentStatus | null;
  appointmentStatus?: ClinicalAppointmentStatus | null;
  start: any;
  end: any;
  details?: string | null;
  recurrence?: ClinicalAppointmentRecurrenceInput | null;
}

export interface CreateClinicalEventForMeInput {
  eventData: CreateClinicalEventInput;
}

export interface CreateClinicalEventInput {
  eventType: ClinicalEventType;
  start: any;
  end: any;
  recurrence?: ClinicalEventRecurrenceInput | null;
}

export interface CreateMemberCoachingSessionInput {
  startTime: any;
  endTime: any;
  note?: string | null;
  memberTimezone?: string | null;
  memberId: string;
  recurrence?: CreateMemberCoachingSessionRecurrenceInput | null;
  sessionFormat?: AvailabilityTypeEnum | null;
  eventType?: CoachingSessionEventType | null;
}

export interface CreateMemberCoachingSessionRecurrenceInput {
  everyNWeeks: number;
  numOccurrences: number;
}

export interface CreatePreSignedUrlInput {
  fileId: string;
  filename?: string | null;
  groupId?: string | null;
  itemId: string;
}

export interface CreateUserMetadataInput {
  userId: string;
  memberId: string;
  latestTimestamp: any;
}

export interface CreateVaultItemInput {
  item: VaultItemInput;
  key: VaultItemKeyInput;
  sharedGroupKey?: SharedGroupKeyInput | null;
  sharedGroupKeys?: SharedGroupKeyInput[] | null;
  sharedSystemKey?: SharedSystemKeyInput | null;
  sharedUserKeys?: SharedUserKeyInput[] | null;
  tags: string[];
}

export interface DateTimeRange {
  startDatetime?: any | null;
  endDatetime?: any | null;
}

export interface DeleteClinicalEventForMeInput {
  id: string;
}

export interface DeleteVaultItemInput {
  groupId?: string | null;
  id: string;
  shareWithSystems?: VaultSystemName[] | null;
}

export interface DeleteVaultItemsInput {
  groupId?: string | null;
  items: string[];
  shareWithSystems?: VaultSystemName[] | null;
}

export interface DemographicsInput {
  firstName?: string | null;
  lastName?: string | null;
  dateOfBirth?: any | null;
  email?: string | null;
  phoneNumber?: string | null;
  gender?: string | null;
  address?: string | null;
  city?: string | null;
  state?: string | null;
  zipCode?: string | null;
  emergencyContactName?: string | null;
  emergencyContactPhone?: string | null;
  emergencyContactRelationship?: string | null;
  insuranceProvider?: string | null;
  insuranceId?: string | null;
  preferredPharmacy?: string | null;
}

export interface EscalateMemberToClinicalInput {
  memberId: string;
}

export interface EscalateTeenToClinicalMutationInput {
  gingerId: number;
  clinicianNeed: string;
}

export interface GetAvailableTimeSlotsForMeInput {
  duration: number;
  startDateTime: any;
  endDateTime: any;
  recurrenceSpec?: RecurrentSessionSpec | null;
  availabilityTypes?: (AvailabilityTypeEnum | null)[] | null;
}

export interface GetCSCOutreachDataInput {
  userId: string;
}

export interface GetClinicalAppointmentInput {
  id: string;
}

export interface GetClinicalAppointmentsForMeInput {
  filters?: ClinicalAppointmentFiltersInput | null;
  pagination?: PaginationInput | null;
}

export interface GetClinicalAppointmentsForMemberInput {
  filters?: ClinicalAppointmentFiltersInput | null;
  pagination?: PaginationInput | null;
  memberId: string;
  orderBy?: (string | null)[] | null;
}

export interface GetClinicalEventInput {
  id: string;
}

export interface GetClinicalEventsForMeInput {
  filters?: ClinicalEventFiltersInput | null;
  pagination?: PaginationInput | null;
}

export interface GetClinicalMembersForMeInput {
  query: string;
}

export interface GetClinicianAvailabilitiesInput {
  windowStart: any;
  windowEnd: any;
}

export interface GetClinicianByIdAndTimeRange {
  clinicianId: string;
  startDate?: any | null;
  endDate?: any | null;
}

export interface GetClinicianInput {
  id: string;
}

export interface GetClinicianTimeAvailableInput {
  appointmentId?: string | null;
  clinicianId: string;
  start: any;
  end: any;
}

export interface GetCliniciansInput {
  filters?: CliniciansFiltersInput | null;
  pagination?: PaginationInput | null;
}

export interface GetCoachingMemberForMeInput {
  query: string;
}

export interface GetEligibleSICoachesForMemberInput {
  memberId: string;
}

export interface GetEventsInput {
  windowStart: any;
  windowEnd: any;
}

export interface GetHomeworkForMemberInput {
  memberId: string;
}

export interface GetLatestCompleteClinicalAppointmentInput {
  appointmentId: string;
}

export interface GetMemberInput {
  id: string;
}

export interface GetMemberIntakeSurveyInput {
  id: string;
}

export interface GetMemberServiceProvisionsInput {
  memberId: string;
  careService?: CareServiceProvisionType | null;
  referenceDatetime?: any | null;
}

export interface GetMemberSessionUsageInput {
  memberId: string;
  fromDate: any;
  toDate: any;
}

export interface GetMemberUpcomingCoachingSessionsInput {
  memberId: string;
}

export interface GetMembersForClinicianInput {
  q?: string | null;
  clinicianId: string;
  pagination?: PaginationInput | null;
}

export interface GetMembersForMeInput {
  q?: string | null;
  pagination?: PaginationInput | null;
}

export interface GetPharmaciesInput {
  name?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  address?: string | null;
  ncpdpID?: string | null;
}

export interface GetPreferredPharmacyInput {
  memberId: string;
}

export interface GetPrescriptionsForMemberInput {
  memberId: string;
}

export interface GetTasksForMemberInput {
  id: string;
}

export interface GetUserCollaborationMetadataInput {
  userId: string;
  memberId: string;
}

export interface GetVaultItemsByTagInput {
  groupId?: string | null;
  pagination: VaultPaginationInput;
  tag: string;
}

export interface InitiateVaultAuthChallengeInput {
  userId: string;
}

export interface InsuranceProviderInput {
  patientId: string;
  carrierId?: string | null;
  company: string;
  idNumber: string;
}

export interface LockVaultItemInput {
  id: string;
}

export interface PaginationInput {
  cursor?: string | null;
  maxItemsPerPage: number;
  sortDirection?: SortDirection | null;
}

export interface RecurrentSessionSpec {
  cadence: AppointmentCadence;
  numberOfSessions: number;
}

export interface RemoveHomeworkFromMemberInput {
  taskId: string;
  memberId: number;
}

export interface ShareVaultItemsInput {
  groups: ShareWithVaultUserOrGroupInput[];
  itemIds: string[];
  setItemPermissionsToReadOnly?: boolean | null;
  systems: ShareWithVaultSystemInput[];
  users: ShareWithVaultUserOrGroupInput[];
}

export interface ShareWithVaultSystemInput {
  key: VaultItemKeyInput;
  name: VaultSystemName;
}

export interface ShareWithVaultUserOrGroupInput {
  id: string;
  key: VaultItemKeyInput;
}

export interface SharedGroupKeyInput {
  group: string;
  key: VaultItemKeyInput;
}

export interface SharedSystemKeyInput {
  key: VaultItemKeyInput;
  system: VaultSystemName;
}

export interface SharedUserKeyInput {
  key: VaultItemKeyInput;
  user: string;
}

export interface TransferMemberToSICoachInput {
  memberId: string;
}

export interface UpdateCareProviderTaskFieldsInput {
  id: string;
  status?: Status | null;
  viewAt?: any | null;
}

export interface UpdateChatConversationTimetokenInput {
  conversationId: string;
  listenerReadTimetoken?: string | null;
  listenerWriteTimetoken?: string | null;
}

export interface UpdateClinicalAppointmentForClinicianInput {
  id: string;
  appointmentData: UpdateClinicalAppointmentInput;
}

export interface UpdateClinicalAppointmentForMeInput {
  id: string;
  appointmentData: UpdateClinicalAppointmentInput;
}

export interface UpdateClinicalAppointmentInput {
  type: ClinicalAppointmentType;
  status?: ClinicianAppointmentStatus | null;
  appointmentStatus?: ClinicalAppointmentStatus | null;
  start: any;
  end: any;
  cancelledReason?: string | null;
  details?: string | null;
}

export interface UpdateClinicalEventForMeInput {
  id: string;
  eventData: UpdateClinicalEventInput;
}

export interface UpdateClinicalEventInput {
  eventType: ClinicalEventType;
  start: any;
  end: any;
}

export interface UpdateClinicianMemberRelationshipInput {
  memberId: string;
}

export interface UpdateClinicianPreferencesMutationInput {
  timezone?: any | null;
  maxAppointmentsPerWeek?: any | null;
  calendarSyncEnabled?: boolean | null;
}

export interface UpdateListenerShiftStatusInput {
  shiftStatus: CoachShiftStatusEnum;
}

export interface UpdateMemberChartIndicatorInput {
  memberId: string;
  chartAreaViewed: ChartAreaViewed;
}

export interface UpdateMemberCoachingSessionInput {
  startTime: any;
  endTime: any;
  note?: string | null;
  memberTimezone?: string | null;
  coachingSessionId: string;
  modificationScope?: CoachingSessionModificationScope | null;
}

export interface UpdatePatientPreferredPharmacyInput {
  memberId: string;
  pharmacyId: string;
}

export interface UpdateUserMetadataInput {
  id: string;
  latestTimestamp: any;
}

export interface UpdateVaultItemInput {
  groupId?: string | null;
  item: VaultItemInput;
  sourceVersion?: string | null;
  tags: string[];
}

export interface VaultEncryptedDataInput {
  cipherText: string;
  encoding: VaultEncoding;
  encryptionVersion: VaultEncryptionVersion;
  nonce: string;
}

export interface VaultItemInput {
  encryptedData: VaultEncryptedDataInput;
  id: string;
  permissions: VaultItemPermissions;
}

export interface VaultItemKeyInput {
  encryptedData: VaultEncryptedDataInput;
  encryptedWith: VaultKeyType;
}

/**
 * Settings to control pagination (e.g. page size, sort order etc)
 */
export interface VaultPaginationInput {
  cursor?: string | null;
  maxItemsPerPage: number;
  sortField: VaultItemSortField;
  sortOrder: VaultItemSortOrder;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
