import { CoverageType } from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { GetMemberById_getMember } from '@headspace/carehub-graphql/dist/queries/generated/GetMemberById';
import { useAppState } from 'app/state';
import { SchedulerService } from 'app/state/features/scheduler/SchedulerService';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { classNameCombiner, isCoachOrSupervisor } from 'utils';

import { Demographics } from './Demographics';
import styles from './MemberHeaderBar.module.scss';
import { SpecialtyTagsContainer } from './SpecialtyTagsContainer';
import { formatCustomerType, formatGuardianRelationship } from './utils';

interface TagsAndDemographicsProps {
  memberId: string;
  getMember: GetMemberById_getMember;
}

// this is a safe length of a string that does not lead to the overlap
const maxBottomLineLength = 49;

export function TagsAndDemographics({
  memberId,
  getMember,
}: TagsAndDemographicsProps) {
  const { isD2c } = getMember;
  const { transientFeatureFlags } = useFeatureFlags();
  const {
    enable_d2c_coaching_coverage_info,
    enable_coaching_scheduler: enableCoachingScheduler,
  } = transientFeatureFlags;

  const payerType = formatCustomerType(
    getMember,
    enable_d2c_coaching_coverage_info,
  );
  const guardianRelationship = formatGuardianRelationship(
    getMember.guardianRelationship?.guardianRelationshipType,
  );

  const bottomLineText = [payerType, guardianRelationship]
    .filter(Boolean)
    .join(' • ');

  const { role } = useAppState(({ user }) => ({
    role: user.role,
  }));

  const dispatch = useDispatch();

  const isCoach = isCoachOrSupervisor(role);

  useEffect(() => {
    isCoach &&
      dispatch(SchedulerService.getMemberSessionUsage({ isD2c, memberId }));
  }, [isD2c, isCoach, memberId, dispatch]);

  const isLongBottomLineCase = bottomLineText.length >= maxBottomLineLength;
  const isHeadspaceEAP =
    getMember.coverageDetails?.coverageType === CoverageType.EAP &&
    getMember.coverageDetails?.eapCarrierName === 'CCA';

  return (
    <div
      className={
        isLongBottomLineCase
          ? classNameCombiner([styles.bottomLineContainer, styles.row])
          : styles.bottomLineContainer
      }
    >
      <SpecialtyTagsContainer
        preferredLanguage={getMember.preferredLanguage}
        guardianRelationshipType={
          getMember.guardianRelationship?.guardianRelationshipType
        }
        isFusion={Boolean(getMember.isFusion)}
        isD2c={Boolean(getMember.isD2c)}
        isVideoCoachingEligible={
          Boolean(getMember.isVideoCoachingEligible) && enableCoachingScheduler
        }
        isHeadspaceEAP={isHeadspaceEAP}
        memberId={memberId}
      />
      <Demographics
        member={getMember}
        bottomLineText={bottomLineText}
        isLongBottomLineCase={isLongBottomLineCase}
      />
    </div>
  );
}
